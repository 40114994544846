import FastClick from 'fastclick';

// import Cookie from 'js-cookie';
// import Underscore from 'underscore';
// import Loadsh from 'loadsh';

export default {
    install: function (Vue) {
        FastClick.attach(document.body);

        // Object.defineProperty(Vue.prototype, '$cookie', { value: Cookie });
        // Object.defineProperty(Vue.prototype, '$underscore', { value: Underscore });
        // Object.defineProperty(Vue.prototype, '$loadsh', { value: Loadsh });  // lodash v4.17.11 = version 0.0.4
    }
}