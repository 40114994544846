import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/* todo global style */
import '@/assets/style/app.css';

/* todo global element-ui */
import '@/assets/style/element.scss';
import { Button, Tag, Icon, Image, Table, TableColumn, Card, Timeline, TimelineItem, Popover, Dialog, Loading, Form, FormItem, Input, Dropdown, DropdownMenu, DropdownItem } from 'element-ui'

Vue.use(Button);
Vue.use(Tag);
Vue.use(Icon);
Vue.use(Image);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Card);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Popover);
Vue.use(Dialog);
Vue.use(Loading.directive);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.prototype.$loading = Loading.service;

/* todo glabal plugins */
import Plugins from '@/common/plugins'
Vue.use(Plugins);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
