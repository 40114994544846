import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
Vue.use(VueRouter);

const createRouter = () => new VueRouter({
    mode: 'hash',
    routes: [
        {
            path: '/',
            name: 'Index',
            component: () => import('@/pages/index')
        },
        {
            path: '/login',
            name: 'Login',
            component: () => import('@/pages/login')
        },
        {
            path: '/register',
            name: 'Register',
            component: () => import('@/pages/register')
        },
        {
            path: '/404',
            name: 'NotFound',
            component: () => import('@/pages/404')
        },
        {
            path: '*',
            redirect: { name: 'NotFound' }
        }
    ]
})

const router = createRouter();

router.beforeEach((to, from, next) => {
    NProgress.start();
    next();
})

router.afterEach(() => {
    NProgress.done();
})

export default router;