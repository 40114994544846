<template>
    <div id="app" :class="{ 'global-mobile': isMobile }">
        <router-view></router-view>
    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "app",
    data() {
        return {
            isMobile: false,
        };
    },
    created() {
        console.info("name：%cChenyi", "color:#29298E");
        console.info("email：%c457258052@qq.com", "color:#29298E");
    },
    mounted() {
        // 监听窗口变化
        window.onresize = () => {
            this.throttle(() => {
                // PC端或移动端相互切换时需重新渲染页面，确保swiper插件正常运行及生效三端一体UI响应式
                if (this.isMobile != this.isMobileDevice()) {
                    window.location.reload();
                }
            }, 500);
        };

        // 判断用户是否为移动端设备
        this.isMobile = this.isMobileDevice();
        this.dispatchMobile(this.isMobile);

        // 阻止用户缩放
        this.preventUserScalable();
    },
    methods: {
        ...mapActions("global", { dispatchMobile: "setMobile" }),
        isMobileDevice() {
            if (
                window.navigator.userAgent.match(
                    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
                )
            ) {
                return true;
            }
            return false;
        },
        throttle(callBack, time) {
            let timer = null;

            if (!timer) {
                timer = setTimeout(() => {
                    callBack();
                    timer = null;
                }, time);
            }
        },
        preventUserScalable() {
            // 阻止手动缩放
            document.addEventListener("gesturestart", function (e) {
                e.preventDefault();
            });

            // 阻止双击缩放
            document.addEventListener("touchstart", function (e) {
                if (e.touches.length > 1) {
                    e.preventDefault();
                }
            });

            // 阻止短时间内的触摸
            let lastTouchEnd = 0;
            document.addEventListener(
                "touchend",
                function (e) {
                    let now = new Date().getTime();
                    if (now - lastTouchEnd <= 300) {
                        e.preventDefault();
                    }
                    lastTouchEnd = now;
                },
                false
            );
        },
    },
};
</script>

<style>
/* 自定义滚动条样式 */
body::-webkit-scrollbar,
.alive-side-navbar::-webkit-scrollbar {
    width: 6px;
}
body::-webkit-scrollbar-thumb,
.alive-side-navbar::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 6px;
}
body::-webkit-scrollbar-track,
.alive-side-navbar:hover::-webkit-scrollbar-track {
    background-color: rgba(135, 135, 135, 0.1);
}
body::-webkit-scrollbar-thumb,
.alive-side-navbar:hover::-webkit-scrollbar-thumb {
    background-color: rgba(135, 135, 135, 0.4);
}
</style>
