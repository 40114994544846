export default {
    namespaced: true,
    state: {
        isMobile: false
    },
    getters: {
        GET_MOBILE(state) {
            return state.isMobile;
        }
    },
    mutations: {
        SET_MOBILE(state, payload) {
            state.isMobile = payload;
        }
    },
    actions: {
        setMobile(context, payload) {
            context.commit('SET_MOBILE', payload);
        }
    }
}